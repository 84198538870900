import { useLocation } from "react-router-dom";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { useSelector, useDispatch } from "react-redux";
import { artcodey } from "../assets";
import { navigation } from "../constants";
import { Button } from "./Button";
import MenuSvg from "../assets/svg/MenuSvg";
import axios from "axios"
import {BaseURL} from "../server.js"
import { HamburgerMenu } from "./design/Header";
import { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import { useStateContext } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";
import { media } from "../server";
import { loadUser } from "../redux/actions/user";
const mobileLogin=[
   {
    id: "4",
    title: "New account",
    url: "/SignUp",
    onlyMobile: true,
  },
  {
    id: "5",
    title: "Sign in",
    url: "/Login",
    onlyMobile: true,
  },
  ]
const Header = () => {
  
  const { isAuth, user } = useSelector((state) => state.user);
  const pathname = useLocation();
  const [openNavigation, setOpenNavigation] = useState(false);
  const { t } = useTranslation();
  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };
  
  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);
  };
  const { currentDir, currentLang, setLang, setDir } = useStateContext();
  const themLangHandeler = () => {
    if (currentDir === "rtl") {
      setDir("ltr");
    } else if (currentDir === "ltr") {
      setDir("rtl");
    }
  };
   const logoutHandler = () => {
    axios.get(`${BaseURL}/user/logout`, { withCredentials: true })

      .then((res) => {
        

        window.location.reload(true);

      })

      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div
      dir="ltr"
      className={`fixed top-0 left-0 w-full z-50  border-b border-n-6 bg-transparent lg:backdrop-blur-xl ${
        openNavigation ? "bg-transparent  " : " bg-transparent backdrop-blur-xl"
      }`}
    >
      <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
        <a className="block w-[12rem] xl:mr-8" href="#hero">
          <img src={artcodey} width={190} height={40} alt="artcdey" />
        </a>

        <nav
          className={`${
            openNavigation ? "flex" : "hidden"
          } fixed top-[5rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent`}
        >
          <div className="relative z-2 flex flex-col items-center justify-center mx-auto lg:flex-row">
        
            {navigation.map((item) => (
              <Link
                key={item.id}
                to={item.url}
                onClick={handleClick}
                className={`block relative  h3 text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
                  item.onlyMobile ? "lg:hidden" : ""
                } px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
                  item.url === pathname.hash
                    ? "z-2 lg:text-n-1"
                    : "lg:text-n-1/50"
                } lg:leading-5 lg:hover:text-n-1 xl:px-12`}
              >
                {t(item.title)}
              </Link>
            ))}
        {!isAuth && mobileLogin.map((item) => (
              <a
                key={item.id}
                href={item.url}
                onClick={handleClick}
                className={`block relative h3 text-2xl uppercase text-n-1 transition-colors hover:text-color-1 lg:hidden
                 px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
                  item.url === pathname.hash
                    ? "z-2 lg:text-n-1"
                    : "lg:text-n-1/50"
                } lg:leading-5 lg:hover:text-n-1 xl:px-12`}
              >
                {t(item.title)}
              </a>
            ))}
              {isAuth && (
                 <p onClick={logoutHandler} className="h3 mx-auto md:my-8 text-2xl text-n-1">{t("Logout")}</p>
)}
          </div>

          <HamburgerMenu />
        </nav>
        <span
          className="button ml-auto font-kufi mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block cursor-pointer"
          onClick={themLangHandeler}
        >
          {currentDir === "rtl" ? <span>English</span> : <span>العربية</span>}
        </span>
        {!isAuth ? (
          <>
            <a
              href="/SignUp"
              className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
            >
              {t("New account")}
            </a>{" "}
            <Button className="hidden lg:flex" href="/Login">
              {t("Sign in")}
            </Button>
          </>
        ) : (
          <div  className="hidden lg:flex w-[50px] h-[50px] flex items-center " >
            <img
              src={`${media}${user.avatar}`}
              alt=""
              className="w-[50px] h-[50px] rounded-full border-[3px] border-[#0eae88]"
            /><p className="body-2 flex mx-2">مرحبا <span className="body-1 text-color-1">{user.name}</span></p>
          </div>
        )}

        <Button
          className="ml-auto lg:hidden"
          px="px-3"
          onClick={toggleNavigation}
        >
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
