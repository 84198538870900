import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Section from "../components/Section";
import { PinContainer } from "../components/ui/3d-pin.tsx";
import { media, BaseURL } from "../server.js";
import axios from "axios";
import Heading from "../components/Heading.jsx";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../context/ContextProvider.js";
import Loader from "../components/Loader.jsx";
function Project() {
  const { id } = useParams();
  const { currentDir } = useStateContext();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    const getProject = async () => {
      try {
        const { data } = await axios.get(`${BaseURL}/project/${id}`);
        setProject(data.project);
        setIsLoading(false);
        console.log("Fetched project:", data.project);
      } catch (error) {
        console.log(error.response?.data?.message || "Error fetching project");
        setIsLoading(false);
      }
    };
    getProject();
  }, [id]); // Added `id` to the dependency array

  return (
    <Section>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container flex-1 ">
          <Heading
            title={currentDir == "ltr" ? project?.titleEn : project?.titleAr}
            text={currentDir == "ltr" ? project?.textEn : project?.textAr}
          />
          <div className="  w-full lg:mt-10 md:mt-10 backdrop-blur-md rounded-2xl p-5 align-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-n-1/10">
            {project?.Images.map((item, index) => (
              <div key={index}>
                <AnimatedPinDemo project={project} item={item} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Section>
  );
}

const AnimatedPinDemo = ({ item, project }) => {
  const { currentDir } = useStateContext();
  return (
    <div className="w-full flex items-center justify-center">
      <PinContainer
        title={
          currentDir == "ltr"
            ? project?.titleEn
            : project.titleAr
            ? project.titleAr
            : project.titleEn
        }
        href="#"
      >
        <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] max-h-[20rem]">
          <img
            src={`${media}${item}`}
            alt={project?.title}
            className="flex flex-1 w-full rounded-lg bg-contain "
            loading="lazy"
          />
        </div>
      </PinContainer>
    </div>
  );
};

export default Project;
