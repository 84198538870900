import { curve, heroBackground, robot } from "../assets";
import {Button} from "./Button";
import Section from "./Section";
import { BackgroundCircles, BottomLine, Gradient } from "./design/Hero";
import { heroIcons } from "../constants";
import { ScrollParallax } from "react-just-parallax";
import { useRef } from "react";

import {Vortex} from "./ui/vortex.jsx"
import CompanyLogos from "./CompanyLogos";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../context/ContextProvider";
import { MoveBorder } from "./ui/moving-border";

const Hero = () => {
  const parallaxRef = useRef(null);
  const { t } = useTranslation();
  const { setCurrentLang, currentLang, setCurrentDir, currentDir, setDir } =
    useStateContext();
  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >              <div className="absolute  -right-5 md:left-1/2 lg:left-1/2 w-[200%] translate-x-1/2 md:-top-[46%] md:w-[100%] lg:top-2 ">
        <img
          src={heroBackground}
          className="w-full"
          width={1440}
          height={1800}
          alt="hero"
        />
      </div>

      <div className="container  relative" ref={parallaxRef}>
        <div
          className={`relative z-1 max-w-[62rem] mx-auto  text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem] ${
            currentLang === "ar" ? "font-kufi" : ""
          }`}
        >
          <h2 className="h2 mb-6">
            {t(
              "Get ready to immerse your senses in the beauty of designs and the splendor of innovation with"
            )}{" "}
            <span className="inline-block relative h1 text-transparent bg-clip-text bg-gradient-to-r from-[#7158ef] to-[#e83e9e]">
              ArtCodey{" "}
              <img
                src={curve}
                className="absolute top-full left-0 w-full xl:-mt-2 "
                width={624}
                height={28}
                alt="Curve"
              />
            </span>
          </h2>
          <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8">
            {t(
              "We are a specialized company in developing smartphone applications and creative web developing design, animation, and 3D design. Our aim is to provide innovative and exceptional digital solutions that meet your business needs and help you achieve your goals"
            )}
          </p>
          <Button href="https://wa.me/+967773455454" white>
            {t("Get started")}
          </Button>
        </div>
        <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
          {/*   <div className="relative z-1 p-0.5 rounded-2xl bg-conic-gradient">
            <div className="relative bg-n-8 rounded-[1rem]">
                <div className="aspect-[30/40] rounded-t-[0.9rem] rounded-b-[0.9rem] overflow-hidden md:aspect-[688/490] lg:aspect-[1024/490]">
                <img
                  src={robot}
                  className="w-full scale-[1.7] translate-y-[8%] md:scale-[1] md:-translate-y-[10%] lg:-translate-y-[23%]"
                  width={1024}
                  height={1024}
                  alt="AI"
                />
         
                <ScrollParallax isAbsolutelyPositioned>
                  <ul className="hidden absolute -left-[5.5rem] bottom-[7.5rem] px-1 py-1 bg-n-9/40 backdrop-blur border border-n-1/10 rounded-2xl xl:flex">
                    {heroIcons.map((icon, index) => (
                      <li className="p-5" key={index}>
                        <img src={icon} width={24} height={25} alt={icon} />
                      </li>
                    ))}
                  </ul>
                  </ScrollParallax>
                <BackgroundHero className="absolute " />
                <ScrollParallax isAbsolutelyPositioned>
                  <Notification
                    className="hidden font-kufi absolute -right-[5.5rem] bottom-[11rem] w-[20
                      rem] xl:flex"
                    title={t("Clint satisfaction is our priority")}
                  />
                </ScrollParallax>
              </div>

            <Gradient />
                    </div>*/}
          {/* <div className="absolute -top-[54%] left-1/2 w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[200%]">
            <img
              src={heroBackground}
              className="w-full"
              width={1440}
              height={1800}
              alt="hero"
            />
          </div>*/}

          <BackgroundCircles />
        </div>

        <CompanyLogos className=" relative z-10 mt-20 lg:block" />
     
</div>

      <BottomLine />

    </Section>
  );
};

export default Hero;
