import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./context/ContextProvider";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import Store from "./redux/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {" "}
    <ContextProvider>
      <Provider store={Store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Provider>
    </ContextProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();
