import React from "react";
import { useStateContext } from "../context/ContextProvider";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
// استخدم مكتبة أيقونات مناسبة للويب
import { useTranslation } from "react-i18next";
import { GreenButton, RedButton } from "./Button";

const VerificationModel = ({
  modalVisible,
  setModalVisible,
  requestMessage,
  successful,
  persistLoginAfterOTPVerification,
}) => {
  const buttonHandler = () => {
    if (successful) {
      persistLoginAfterOTPVerification();
    }
    setModalVisible(false);
  };

  if (!modalVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-n-8 border border-n-2/50 rounded-md min-w-[20rem] shadow-md max-w-xl p-10 relative items-center justify-center">
        <div>
          {!successful ? (
            <FailContent
              buttonHandler={buttonHandler}
              errorMsg={requestMessage}
            />
          ) : (
            <SuccessContent buttonHandler={buttonHandler} />
          )}
        </div>
      </div>
    </div>
  );
};

const SuccessContent = ({ buttonHandler }) => {
  // قم بتخصيص الألوان حسب الوضع
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center flex-col">
      <div className=" absolute  -top-5 bg-color-8  flex items-center justify-center rounded-full p-3 center">
        <IoMdCheckmarkCircleOutline size={30} />
      </div>
      <h2 className="h2 text-color-8 my-3">{t("Verified")}</h2>
      <p className="mb-2 body-1">
        {t("You have successfully verified your account")}
      </p>
      <GreenButton onClick={buttonHandler}>
        {t("Continue to login")}
      </GreenButton>
    </div>
  );
};

const FailContent = ({ errorMsg, buttonHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center flex-col">
      <div className=" absolute  -top-5 bg-color-7  flex items-center justify-center rounded-full p-3 center">
        <MdErrorOutline size={30} />
      </div>
      <h2 className="h2 text-color-7 my-3">{t("Failed")}</h2>
      <p className="mb-2 body-1">
        {t("Oops! Account verification failed")} {errorMsg}
      </p>
      <RedButton onClick={buttonHandler}>{t("Try Again")}</RedButton>
    </div>
  );
};

export default VerificationModel;
