import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Section from "../components/Section";
import ReactPlayer from 'react-player/lazy'
import { media, BaseURL } from "../server.js";
import axios from "axios";
import Heading from "../components/Heading.jsx";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../context/ContextProvider.js";

import Loader from "../components/Loader.jsx";
function VideosPage() {
  const { id } = useParams();
  const { currentDir } = useStateContext();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    const getProject = async () => {
      try {
        const { data } = await axios.get(`${BaseURL}/project/${id}`);
        setProject(data.project);
        setIsLoading(false);
        console.log("Fetched project:", data.project);
      } catch (error) {
        console.log(error.response?.data?.message || "Error fetching project");
        setIsLoading(false);
      }
    };
    getProject();
  }, [id]); // Added `id` to the dependency array

  return (
    <Section>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container flex-1 ">
          <Heading
            title={currentDir == "ltr" ? project?.titleEn : project?.titleAr}
            text={currentDir == "ltr" ? project?.textEn : project?.textAr}
          />
          <div className="  w-full lg:mt-10 md:mt-10 backdrop-blur-md rounded-2xl p-5 align-center border border-n-1/10">
           
<ResponsiveVideoPlayer link={project.link} />
          </div>
        </div>
      )}
    </Section>
  );
}



const ResponsiveVideoPlayer = ({link}) => {
  const [playbackRate, setPlaybackRate] = useState(1.0);

  const handlePlaybackRateChange = (event) => {
    setPlaybackRate(parseFloat(event.target.value));
  };

  return (
    <div style={{ maxWidth: '100%', margin: 'auto' }}>
      <ReactPlayer
        url={link} // ضع رابط الفيديو هنا
        controls
        width="100%"
        height="auto"
        
      />

    </div>
  );
};




export default VideosPage;
