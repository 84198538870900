import { configureStore } from "@reduxjs/toolkit";
import servicesReducer from "./reducers/service";
import projectsReducer from "./reducers/project";
import orderReducer from "./reducers/project";
import userReducer from './reducers/user.js'
const Store = configureStore({
  reducer: {
    services: servicesReducer,
    projects: projectsReducer,
    order:orderReducer,
    user:userReducer,
  },
});

export default Store;
