export const ButtonGradient = () => {
  return (
    <svg className="block" width={0} height={0}>
      <defs>
        <linearGradient id="btn-left" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#7158ef" />
          <stop offset="100%" stopColor="#e83e9e" />
        </linearGradient>
        <linearGradient id="btn-top" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#7158ef" />
          <stop offset="100%" stopColor="#e83e9e" />
        </linearGradient>
        <linearGradient id="btn-bottom" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#e83e9e" />
          <stop offset="100%" stopColor="#7158ef" />
        </linearGradient>
        <linearGradient
          id="btn-right"
          x1="14.635%"
          x2="14.635%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#e83e9e" />
          <stop offset="100%" stopColor="#7158ef" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ButtonGREEN = () => {
  return (
    <svg className="block" width={0} height={0}>
      <defs>
        <linearGradient id="btn-green-left" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#00fd10" />
          <stop offset="100%" stopColor="#00fd10" />
        </linearGradient>
        <linearGradient id="btn-green-top" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#00fd10" />
          <stop offset="100%" stopColor="#00fd10" />
        </linearGradient>
        <linearGradient id="btn-green-bottom" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#00fd10" />
          <stop offset="100%" stopColor="#00fd10" />
        </linearGradient>
        <linearGradient
          id="btn-green-right"
          x1="14.635%"
          x2="14.635%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#00fd10" />
          <stop offset="100%" stopColor="#00fd10" />
        </linearGradient>
      </defs>
    </svg>
  );
};


export const ButtonRED = () => {
  return (
    <svg className="block" width={0} height={0}>
      <defs>
        <linearGradient id="btn-red-left" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#f01e2c" />
          <stop offset="100%" stopColor="#f01e2c" />
        </linearGradient>
        <linearGradient id="btn-red-top" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#f01e2c" />
          <stop offset="100%" stopColor="#f01e2c" />
        </linearGradient>
        <linearGradient id="btn-red-bottom" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#f01e2c" />
          <stop offset="100%" stopColor="#f01e2c" />
        </linearGradient>
        <linearGradient
          id="btn-red-right"
          x1="14.635%"
          x2="14.635%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#f01e2c" />
          <stop offset="100%" stopColor="#f01e2c" />
        </linearGradient>
      </defs>
    </svg>
  );
};

