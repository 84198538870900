import { useState } from "react";
import { motion } from "framer-motion";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { logo5, logo4, logo3, logo2, logo1 } from "../assets";
import Section from "./Section";
import { useStateContext } from "../context/ContextProvider";
import { MoveBorder } from "./ui/moving-border";
import { useTranslation } from "react-i18next";
const ImageSlider = () => {
  const { t } = useTranslation();
  const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);

  const { currentLang } = useStateContext();
  const handleNext = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex + 1) % 5
      );
      return updatedIndexes;
    });
  };

  const handleBack = () => {
    setPositionIndexes((prevIndexes) => {
      const updatedIndexes = prevIndexes.map(
        (prevIndex) => (prevIndex + 4) % 5
      );

      return updatedIndexes;
    });
  };

  const images = [logo1, logo2, logo3, logo4, logo5];

  const positions = ["center", "left1", "left", "right", "right1"];

  const imageVariants = {
    center: { x: "0%", scale: 1, zIndex: 5 },
    left1: { x: "-50%", scale: 0.7, zIndex: 3 },
    left: { x: "-90%", scale: 0.5, zIndex: 2 },
    right: { x: "90%", scale: 0.5, zIndex: 1 },
    right1: { x: "50%", scale: 0.7, zIndex: 3 },
  };
  return (
    <Section hideStrok={true} id="how-to-use">
      <MoveBorder className="flex items-center flex-col z-10 justify-center h-auto min-w-[24rem] min-h-[15rem] md:min-h-[24rem] lg:min-h-[35rem] backdrop-blur-md   w-full ">
        <div
          className="flex py-20 items-center flex-col justify-center"
          onClick={handleNext}
        >
          {images.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              loading="lazy"
              alt={image}
              className="rounded-[12px]"
              initial="center"
              animate={positions[positionIndexes[index]]}
              variants={imageVariants}
              transition={{ duration: 0.5 }}
              style={{ width: "40%", position: "absolute", top: 20 }}
            />
          ))}
        </div>
        {/*
        <div className="flex lg:mt-14 md:mt-10 flex-row gap-3">
          <button
            className="text-white  rounded-md py-2 px-4"
            onClick={handleBack}
          >
            {currentLang == "ar" ? (
              <FaRegArrowAltCircleRight className="flip-arrow" size={24} />
            ) : (
              <FaRegArrowAltCircleLeft size={24} />
            )}
          </button>
          <button
            className="text-white rounded-md py-2 px-4"
            onClick={handleNext}
          >
            {currentLang == "ar" ? (
              <FaRegArrowAltCircleLeft className="flip-arrow" size={24} />
            ) : (
              <FaRegArrowAltCircleRight size={24} />
            )}
          </button>
        </div>*/}
        <h5 className=" absolute bottom-4 body-2 lg:body-1 md:body-1 mb-3 lg:mb-6 text-center text-n-1">
          {t("We make you source of inspiration for your clints")}
        </h5>
      </MoveBorder>
    </Section>
  );
};

export default ImageSlider;
