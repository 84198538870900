import axios from "axios";
import { BaseURL } from "../../server";

export const getAllProjects = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProjectsRequest",
    });

    const { data } = await axios.get(`${BaseURL}/project`);

    dispatch({
      type: "getAllProjectsSuccess",
      payload: data.projects,
    });
  } catch (error) {
    dispatch({
      type: "getAllProjectsFail",
      payload: error.response.data.message,
    });
  }
};

export const getProject = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProjectsRequest",
    });

    const { data } = await axios.get(`${BaseURL}/project`);

    dispatch({
      type: "getAllProjectsSuccess",
      payload: data.projects,
    });
  } catch (error) {
    dispatch({
      type: "getAllProjectsFail",
      payload: error.response.data.message,
    });
  }
};

//! ///update Editor orders///////
