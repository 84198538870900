import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Section from "../components/Section";
import { useParams } from "react-router-dom";
import { PinContainer } from "../components/ui/3d-pin.tsx";
import { getAllProjects } from "../redux/actions/project.js";
import { BaseURL, media } from "../server.js";
import Heading from "../components/Heading.jsx";
import axios from "axios";
import Loader from "../components/Loader.jsx";
function ServicesPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [service, setService] = useState(null);
  const [isLoading2, setIsLoading2] = useState(true);
  const { isLoading, AllProjects } = useSelector((state) => state.projects);
  const { t } = useTranslation();
  useEffect(() => {
    const getService = async () => {
      try {
        const { data } = await axios.get(`${BaseURL}/service/${id}`);
        setService(data.service);
        setIsLoading2(false);
        console.log("Fetched project:", data.service);
      } catch (error) {
        console.log(error.response?.data?.message || "Error fetching project");
        setIsLoading2(false);
      }
    };
    getService();
  }, [id]); // Added `id` to the dependency array
  useEffect(() => {
    dispatch(getAllProjects());
  }, []);
  const data = AllProjects?.filter((item) => item?.serviceId == id);

  return (
    <Section>
      {isLoading || isLoading2 ? (
        <Loader />
      ) : (
        <div className="container ">
          <Heading title={t(service.title)} text={t(service.text)} />
          <div className="relative w-full lg:mt-10 md:mt-10 backdrop-blur-md rounded-2xl p-5 align-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-n-1/10">
            {data?.map((item) => (
              <div key={item._id}>
                <AnimatedPinDemo service={service} item={item} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Section>
  );
}

export default ServicesPage;

const AnimatedPinDemo = ({ service, item }) => {
  return (
    <div className="w-full flex items-center justify-center ">
<PinContainer
  title={item?.titleEn}
  href={
    service.title === "UI/UX Design" || service.title === "Web & E-commerce Development"
      ? `/UiUxPage/${item._id}`
      : service.title === "Animation Video Design"
      ? `/VideosPage/${item._id}`
      : `/Project/${item._id}`
  }
>


        <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] ">
          <span>{item?.title}</span>
          <img
            src={`${media}${item?.Images[0]}`}
            className="flex flex-1 w-full rounded-lg"
          />
        </div>
      </PinContainer>
    </div>
  );
};
