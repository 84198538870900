import React from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";

import OrderService from "../components/Form/OrderService.jsx";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllServices } from "../redux/actions/service";
import Grid from "../components/Grid";
function HomePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllServices());
  }, []);
  return (
    <>
      <Hero />
      <Services />
      <About />
      <Grid />
      <OrderService />
    </>
  );
}

export default HomePage;
