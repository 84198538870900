
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PinContainer } from "../components/ui/3d-pin.tsx";
import {BaseURL,media } from "../server.js";
import axios from "axios";
import { HeroParallax } from "../components/ui/hero-parallax";
import Section from "../components/Section";
import Loader from "../components/Loader.jsx";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../context/ContextProvider.js";
 function WebSitesPage() {
  const [project, setProject] = useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getProject = async () => {
      try {
        const { data } = await axios.get(`${BaseURL}/project/${id}`);
        setProject(data.project);
        setIsLoading(false);
        console.log("Fetched project:", data.project);
      } catch (error) {
        console.log(error.response?.data?.message || "Error fetching project");
        setIsLoading(false);
      }
    };
    getProject();
  }, [id]); // Added `id` to the dependency array

  return(<Section>
      {isLoading ? (
        <Loader />
      ) : (
      
        <div className="container ">
          <div className="relative w-full lg:mt-10 md:mt-10 backdrop-blur-md rounded-2xl p-5 align-center border border-n-1/10">

                {project?.Images.map((item, index) => (
              <div key={index}>
                <AnimatedPinDemo project={project} item={item} />
              </div>
            ))}
    </div></div>
)}    </Section>
    )
}
const AnimatedPinDemo = ({ item, project }) => {
  const { currentDir } = useStateContext();
  return (
    <div className="w-full flex items-center justify-center">
      <PinContainer
        title={
          currentDir == "ltr"
            ? project?.titleEn
            : project.titleAr
            ? project.titleAr
            : project.titleEn
        }
        href={project.link}
      >
        <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] max-h-[20rem]">
          <img
            src={`${media}${item}`}
            alt={project?.title}
            className="flex flex-1 w-full rounded-lg bg-contain "
            loading="lazy"
          />
        </div>
      </PinContainer>
    </div>
  );
};

export default WebSitesPage