import axios from "axios";
import { BaseURL } from "../../server";

export const getAllServices = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllServicesRequest",
    });

    const { data } = await axios.get(`${BaseURL}/service`);

    dispatch({
      type: "getAllServicesSuccess",
      payload: data.services,
    });
  } catch (error) {
    dispatch({
      type: "getAllServicesFail",
      payload: error.response.data.message,
    });
  }
};
//! ///update Editor orders///////
