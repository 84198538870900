import { benefits } from "../constants";
import Heading from "./Heading";
import Section from "./Section";
import Arrow from "../assets/svg/Arrow";
import { GradientLight } from "./design/Benefits";
import { useSelector } from "react-redux";
import ClipPath from "../assets/svg/ClipPath";
import { useTranslation } from "react-i18next";
import { media } from "../server";
import { Link } from "react-router-dom";
import BackgroundHero from "./design/BackgroundHero";



import Loader from "./Loader";
const Services = () => {
  
  const { isLoading, AllServices } = useSelector((state) => state.services);
  const { t } = useTranslation();
  
  return (
    <Section id="features">
      <div className="container relative mt-4 md:mt-15  lg:mt-15 z-2">
        <Heading className="md:max-w-md lg:max-w-2xl" title="Our services" />

        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-wrap gap-10 mb-10">
            {AllServices?.map((item) => (
              <Link to={`/ServicesPage/${item._id} `}>
                <div
                  className="block relative p-0.5 bg-no-repeat bg-[length:100%_100%] md:max-w-[24rem]"
                  style={{
                    backgroundImage: `url(${media}${item.backgroundUrl})`,
                  }}
                  key={item.id}
                >
                  <div className="relative z-2 flex flex-col min-h-[22rem] p-[2.4rem] pointer-events-none">
                    <h5 className="h5 mb-5">{t(item.title)}</h5>
                    <p className="body-2 mb-6 text-n-3">{t(item.text)}</p>
                    <div className="flex items-center mt-auto">
                      <img
                        src={`${media}${item.iconUrl}`}
                        loading="lazy"
                        width={48}
                        height={48}
                        alt={item.title}
                      />
                      <p className="ltr:ml-auto  rtl:mr-auto ltr:font-code rtl:font-kufi text-xs font-bold text-n-1 uppercase tracking-wider">
                        {t("Explore more")}
                      </p>
                      <Arrow />
                    </div>
                  </div>

                  {item.light && <GradientLight />}

                  <div
                    className="absolute backdrop-blur-xl inset-0.5 bg-n-8/60"
                    style={{ clipPath: "url(#benefits)" }}
                  >
                    <div className="absolute inset-0 opacity-0 transition-opacity hover:opacity-10">
                      {item.imageUrl && (
                        <img
                          src={`${media}${item.imageUrl}`}
                          width={380}
                          height={362}
                          alt={item.title}
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  </div>

                  <ClipPath />
                </div>{" "}
              </Link>
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Services;
