
import { gridItems } from "../constants";
import Section from "./Section";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";
import { useTranslation } from "react-i18next";
function Grid() {
  const { t } = useTranslation();
  return (
  <Section id="AboutIT">
<div className="container z-20">
<BentoGrid className="w-full py-20">{gridItems.map((item,i)=>(
<BentoGridItem 
id={item.id}
key={item.id}
title={t(item.title)}
description={t(item.description)}
className={item.className}
img={item.img}
imgClassName={item.imgClassName}
titleClassName={item.titleClassName}
spareImg={item.spareImg}
/>
      ))}</BentoGrid>
   
</div>


     
</Section>
  );
}

export default Grid;
