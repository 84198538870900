const ClipPath = () => {
  return (
    <svg className="block" width={0} height={0}>
      <clipPath id="benefits" clipPathUnits="objectBoundingBox">
        {/* <path d="M970.54,492.46c1.71,34.81,1.63,69.77,1.7,104.5.2,104.8-.72,209.62-.72,314.45v73.38c-79.4,0-147.43-48.68-175.9-117.81-82.14,66.89-186.91,106.94-301.09,106.94h-4.31c-263.56,0-477.24-213.64-477.24-477.2v-4.31C12.98,228.85,226.65,15.21,490.22,15.21c0,0,4.31,0,4.31,0,134.91,0,268.63,59.93,357.56,161.59,77.7,88.82,112.73,199.24,118.45,315.67Z" />*/}
        <path d="M0.079,0 h0.756 a0.079,0.083,0,0,1,0.058,0.026 l0.086,0.096 A0.079,0.083,0,0,1,1,0.179 V0.917 c0,0.046,-0.035,0.083,-0.079,0.083 H0.079 c-0.044,0,-0.079,-0.037,-0.079,-0.083 V0.083 C0,0.037,0.035,0,0.079,0" />
      </clipPath>
    </svg>
  );
};

export default ClipPath;
