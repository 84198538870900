import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Section from "../components/Section.tsx";
import VerificationModel from "../components/VerificationModel.jsx";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Heading from "../components/Heading";
import ResendTimer from "../components/ResendTimer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { BaseURL } from "../server.js";
import { Button } from "../components/Button.jsx";
const OtpVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [targetTime, setTargetTime] = useState(null);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  let resendTimerInterval;
  const [activeResend, setActiveResend] = useState(false);
  const location = useLocation();
  const { userId, email } = location.state || {};

  const [code, setCode] = useState("");
  const [resendStatus, setResendStatus] = useState("Resend");
  const triggerTimer = (targetTimeInSeconds) => {
    setTargetTime(targetTimeInSeconds);
    setActiveResend(false);

    const finalTime = +new Date() + targetTimeInSeconds * 1000;
    resendTimerInterval = setInterval(() => calculateTimeLeft(finalTime), 1000);
  };
  const calculateTimeLeft = (finalTime) => {
    const difference = finalTime - +new Date();

    if (difference >= 0) {
      setTimeLeft(Math.round(difference / 1000));
    } else {
      clearInterval(resendTimerInterval);
      setActiveResend(true);
      setTimeLeft(null);
    }
  };

  const submitOTPVerificatiod = async () => {
    try {
      const { data } = await axios.post(`${BaseURL}/user/verifyotp`, {
        userId,
        otp: code,
      });

      if (data.success != true) {
        setVerificationSuccessful(false);
        setRequestMessage(data.message);
        alert(data.status);
      } else {
        setVerificationSuccessful(true);
      }
      setModalVisible(true);
      setVerifying(false);
    } catch (error) {
      setRequestMessage(error.message);
      setVerificationSuccessful(false);
      setVerifying(false);
      setModalVisible(true);
    }
  };

  const resendEmail = async () => {
    setResendingEmail(true);
    try {
      await axios.post(`${BaseURL}/user/resend-otp`, { email, userId });
      setResendStatus("Sent!");
    } catch (error) {
      setResendStatus("Field!");
      alert("resend Veritication email faild");
    }
    setResendingEmail(false);
    setTimeout(() => {
      setResendStatus("Resend");
      setActiveResend(false);
      triggerTimer(5000);
    }, 5000);
  };
  const persistLoginAfterOTPVerification = async () => {
    navigate("/Login");
  };
  useEffect(() => {
    triggerTimer();
    return () => {
      clearInterval(resendTimerInterval);
    };
  }, []);
  return (
    <>
      <Section>
        <VerificationModel
          successful={verificationSuccessful}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          requestMessage={requestMessage}
          persistLoginAfterOTPVerification={persistLoginAfterOTPVerification}
        />
        <div className="container relative ">
          <Heading title={t("Enter the code to verify")} />
          <div className=" justify-center items-center bg-conic-gradient p-[2px] mx-auto  rounded-lg  md:mt-0 sm:max-w-md  ">
            <div
              dir="ltr"
              className="flex flex-col w-full  rounded-lg xl:p-10 justify-center items-center bg-n-8 p-5"
            >
              <OtpInput
                value={code}
                onChange={setCode}
                numInputs={5}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputType="number"
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0.5rem",
                  fontSize: "1.5rem",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
              <Button className={"mt-4"} onClick={submitOTPVerificatiod}>
                {" "}
                {t("verify")}
              </Button>
            </div>
          </div>

          <ResendTimer
            activeResend={activeResend}
            resendingEmail={resendingEmail}
            resendStatus={resendStatus}
            timeLeft={timeLeft}
            targetTime={targetTime}
            resendEmail={resendEmail}
          />
        </div>
      </Section>
    </>
  );
};

export default OtpVerification;
