import React, { useState } from "react";
import Section from "../Section";
import Heading from "../Heading";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { sendOrder, clearCreate } from "../../redux/actions/order";
import { BaseURL } from "../../server.js";
import { Label, TextArea, Select, Button } from "./Label";
import { Vortex } from "../ui/vortex.jsx";
function OrderService() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAuth, user } = useSelector((state) => state.user);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const { isLoading, AllServices } = useSelector((state) => state.services);
  const [formData, setFormData] = useState({
    name:isAuth ? user.name :"",
    email:isAuth ? user.email : "",
    phone: isAuth ? user.phoneNumber :  "",
    service: "",
    description: "",
    budget: "",
    deadline: "",
    notes: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoadingCreate === false) {
      try {
        setIsLoadingCreate(true);

        const config = { headers: { "Content-Type": "application/json" } };
        const { data } = await axios.post(
          `${BaseURL}/order`,
          JSON.stringify(formData),
          config
        );

        if (data.success === true) {
          setIsLoadingCreate(false);
          setFormData({
            name: "",
            email: "",
            phone: "",
            service: "",
            description: "",
            budget: "",
            deadline: "",
            notes: "",
          });
        } else if (data.success === false) {
          setIsLoadingCreate(false);
          console.log(data.message);
        }
      } catch (error) {
        setIsLoadingCreate(false);
        console.log(error);
      }
    }
  };

  return (
    <Section id="OrderService">
      <div className="container ">
           <Heading
            title={t("Start your project with us")}
            text={t(
              "Please fill out the form below to provide details of your project. We are here to meet your design and development needs."
            )}
          />
        <Vortex
          rangeY={600}
          particleCount={500}
          ba
          className="flex items-center flex-col justify-center px-2 md:px-10 py-4 w-full h-full"
        >
     

          <form
            onSubmit={handleSubmit}
            className="w-full bg-n-8/5 backdrop-blur-xl rounded-lg shadow dark:border md:mt-0 sm:max-w-md border border-n-1/10  xl:p-10 p-5"
          >
            <Label
              labelText="Full Name"
              value={formData.name}
              onChange={handleChange}
              required={true}
              type="text"
              name="name"
            />

            <Label
              labelText="Email"
              value={formData.email}
              onChange={handleChange}
              required={true}
              type="text"
              name="email"
            />

            <Label
              labelText="Phone Number"
              value={formData.phone}
              onChange={handleChange}
              required={true}
              type="number"
              name="phone"
            />

            <Select
              name="service"
              labelText="Select the type of project required"
              value={formData.service}
              onChange={handleChange}
              required={true}
              AllServices={AllServices}
            />

            <TextArea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required={true}
              type="text"
              labelText="Project Description"
              rows="15"
            />

            <Label
              labelText="Expected budget"
              value={formData.budget}
              name="budget"
              onChange={handleChange}
              required={true}
              type="number"
            />
            <Label
              labelText="Submission deadline"
              value={formData.deadline}
              onChange={handleChange}
              required={true}
              name="deadline"
              type="date"
            />

            <TextArea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              required={true}
              type="text"
              labelText="Additional notes"
              rows="15"
            />

            <Button isLoading={isLoadingCreate}>{t("Submit Request")}</Button>
          </form>
        </Vortex>
      </div>
    </Section>
  );
}

export default OrderService;
