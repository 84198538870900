import React, { useState } from "react";
import { Label, Button } from "../components/Form/Label.jsx";
import Section from "../components/Section.tsx";
import { BaseURL } from "../server.js";
import Heading from "../components/Heading";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useStateContext } from "../context/ContextProvider.js";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visibleConf, setVisibleConf] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [textSuccess, setTextSuccess] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const { currentDir } = useStateContext();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setIsLoadingCreate(true);
    e.preventDefault();
    const config = { headers: { "Content-Type": "application/json" ,
      
        withCredentials: true,}
      
    };
    await axios
      .post(`${BaseURL}/user/login`, (formData),         {withCredentials: true,})
      .then((res) => {
        setTextSuccess(t("Login Success!"));
        setIsLoadingCreate(false);
        navigate("/");
        window.location.reload(true);
      })
      .catch((err) => {
        setIsLoadingCreate(false);
        setTextMessage(t(err.response.data.message));
      });
  };

  return (
    <Section>
      <div className="container ">
        <Heading title={t("Login your account")} />
        <form
          onSubmit={handleSubmit}
          className="w-full mx-auto bg-n-8/5 backdrop-blur-xl rounded-lg shadow dark:border md:mt-0 sm:max-w-md border border-n-1/10  xl:p-10 p-5"
        >
          <Label
            labelText="Email"
            value={formData.email}
            onChange={handleChange}
            required={true}
            type="text"
            name="email"
          />

          <div className="relative">
            <Label
              labelText="Password"
              value={formData.password}
              onChange={handleChange}
              required={true}
              type={visible ? "text" : "password"}
              name="password"
            />
            {visible ? (
              <AiOutlineEye
                className={`text-color-1/80 absolute ${
                  currentDir == "ltr" ? "right-2" : "left-2"
                } top-3 cursor-pointer`}
                size={25}
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className={`text-n-1/80 absolute ${
                  currentDir == "ltr" ? "right-2" : "left-2"
                } top-3 cursor-pointer`}
                size={25}
                onClick={() => setVisible(true)}
              />
            )}
          </div>

          <div className="flex flex-col items-center mx-auto">
            {" "}
            <Button isLoading={isLoadingCreate}>{t("Sign in")}</Button>
            <p className="body-1 mt-2 text-color-7">{t(textMessage)}</p>
            <p className="body-1 mt-2 text-color-8">{t(textSuccess)}</p>
      <p className="body-1 mt-2 text-color-1/50">{t(textSuccess)}</p>
          </div>
          <div className ="w-full mt-5 flex flex-row">
     <p className="body-2 mt-2 text-n-1/50">{t("don't have account ?")}</p>
  <Link to="/SignUp">   <p className="body-1 mt-2 mx-3 text-color-1" >{t("Sign up")}</p></Link></div>
        </form>
      </div>
    </Section>
  );
}

export default Login;
