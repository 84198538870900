import { companyLogos } from "../constants";
import ImageSlider from "./ImageSlider";

const CompanyLogos = ({ className }) => {
  return (
    <div className="w-full ">
      <ImageSlider className="w-min-full" />
    </div>
  );
};

export default CompanyLogos;
