import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/animations/animationData.json";
import { useTranslation } from "react-i18next";

const Loader = () => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-full h-400 flex-col flex items-center justify-center">
      <Lottie options={defaultOptions} width={300} height={300} />

      <h1 className=" text-center body-1  absolute bottom-10 text-purple-500 ">
        {t("Loading ...")}
      </h1>
    </div>
  );
};

export default Loader;
