import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};
const servicesReducer = createReducer(initialState, (builder) => {
  builder

    //! /////edaitor orders//////////////////
    .addCase("getAllServicesRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("getAllServicesSuccess", (state, action) => {
      state.isLoading = false;
      state.AllServices = action.payload;
    })
    .addCase("getAllServicesFail", (state, action) => {
      state.isLoading = false;
      state.err = action.payload;
    });
});

export default servicesReducer;
