import { Suspense, useEffect } from "react";
import {
  ButtonGradient,
  ButtonRED,
  ButtonGREEN,
} from "./assets/svg/ButtonGradient";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServicesPage from "./pages/ServicesPage.jsx";
import VideosPage from "./pages/VideosPage.jsx";
import OtpVerification from "./pages/OtpVerification.jsx";
import SignUp from "./pages/SignUp.jsx";
import Login from "./pages/Login.jsx";
import Project from "./pages/Project.jsx";
import Store from "./redux/store";
import HomePage from "./pages/HomePage.jsx";
import UiUxPage from "./pages/UiUxPage.jsx";
import { useStateContext } from "./context/ContextProvider";
import { useTranslation } from "react-i18next";
import WaittingPage from "./pages/WaittingPage.jsx";
import WebSitesPage from "./pages/WebsitesPage.jsx";
import { loadUser } from "./redux/actions/user";
const App = () => {
  const { setCurrentLang, currentLang, setCurrentDir, currentDir, setDir } =
    useStateContext();
  const { i18n } = useTranslation();
  useEffect(() => {
    const currentThemedir = localStorage.getItem("themeDir");
    const currentThemelang = localStorage.getItem("themeLang");
    if (currentThemedir && currentThemelang) {
      setCurrentDir(currentThemedir);
      setCurrentLang(currentThemelang);
    }
    i18n.changeLanguage(currentLang);
  }, [currentLang]);
  useEffect(() => {
    Store.dispatch(loadUser());
  }, []);
  return (
    <frameElement dir={currentDir === "rtl" ? "rtl" : "ltr"}>
      <Suspense fullback={null}>
        <BrowserRouter>
          <div className="pt-[4.75rem] lg:pt-[5.25rem] bg-[#050714] overflow-hidden">
            <Header />

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Project/:id" element={<Project />} />
              <Route path="/UiUxPage/:id" element={<UiUxPage />} />
              <Route path="/ServicesPage/:id" element={<ServicesPage />} />
              <Route path="/WebSitesPage/:id" element={<WebSitesPage />} />
              <Route path="/VideosPage/:id" element={<VideosPage />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/OtpVerification" element={<OtpVerification />} />
              <Route path="/waitting" element={<WaittingPage />} />
            </Routes>
            <Footer />
          </div>
          <ButtonGREEN />
          <ButtonRED />
          <ButtonGradient />
        </BrowserRouter>
      </Suspense>
    </frameElement>
  );
};

export default App;
