import React from 'react';

const ResendTimer = ({ activeResend, resendEmail, resendingEmail, resendStatus, timeLeft, targetTime }) => {
  const handleResendClick = () => {
    if (activeResend) {
      resendEmail();
    }
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <div>
        <span style={{ fontSize: '16px', color: '#555' }}>
          Didn't receive the email?
        </span>
        {!resendingEmail && (
          <button
            onClick={handleResendClick}
            disabled={!activeResend}
            style={{
              opacity: !activeResend ? 0.5 : 1,
              textDecoration: 'underline',
              background: 'none',
              border: 'none',
              color: '#007BFF',
              cursor: activeResend ? 'pointer' : 'not-allowed',
              marginLeft: '5px',
            }}
          >
            {resendStatus}
          </button>
        )}
        {resendingEmail && (
          <span style={{ marginLeft: '5px' }}>
            <span className="loader"></span> {/* يمكنك استخدام أي مكون لعرض المحمل */}
          </span>
        )}
      </div>
      {!activeResend && (
        <div style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}>
          Didn't receive the email?{' '}
          <strong>
            {`${timeLeft || targetTime} seconds`}
          </strong>
        </div>
      )}
    </div>
  );
};

export default ResendTimer;
