import { useTranslation } from "react-i18next";
import { artcodeySymbol, check, check2 } from "../assets";
import { collabApps, collabContent, collabText } from "../constants";
import {Button} from "./Button";
import Section from "./Section";
import { LeftCurve, RightCurve } from "./design/Collaboration";
import { motion } from "framer-motion";
import { useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
const About = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(1);
  return (
    <Section crosses>
      <div className="container lg:flex">
        <div className="max-w-[25rem]">
          <h2 className="h2 mb-4 md:mb-8">{t("About Company")}</h2>

          <ul className="max-w-[22rem] mb-10 md:mb-14">
            {collabContent.map((item) => (
              <li className="mb-3 py-3" key={item.id}>
                <div
                  className="flex items-center"
                  onClick={() => setSelected(item.id)}
                >
                  {selected == item.id ? (
                    <IoCheckmarkCircle size={24} color={"#AC6AFF"} />
                  ) : (
                    <IoIosCheckmarkCircleOutline size={24} color={"#AC6AFF"} />
                  )}
                  <h6 className="body-2 ltr:ml-5 rtl:mr-5 cursor-pointer">
                    {t(item.title)}
                  </h6>
                </div>
                {selected == item.id && (
                  <p className="body-2 mt-3 text-n-4">{t(item.text)}</p>
                )}
              </li>
            ))}
          </ul>

          <Button href="https://wa.me/967773455454">
            {t("Start with us now")}
          </Button>
        </div>

        <div className="ltr:lg:ml-auto rtl:lg:mr-auto xl:w-[38rem] mt-4">
          <p className="body-2 mb-8 text-n-4 md:mb-16 lg:mb-32 lg:w-[22rem] lg:mx-auto">
            {t(collabText)}
          </p>

          <div className="relative ltr:left-1/2 rtl:right-1/2   flex  w-[22rem] aspect-square border border-n-6 rounded-full ltr:-translate-x-1/2 rtl:translate-x-1/2 scale:75 md:scale-100">
            <div className="flex w-60 aspect-square m-auto border border-n-6 rounded-full">
              <div className="w-[6rem] aspect-square m-auto p-[0.2rem]  bg-conic-gradient rounded-full">
                <div className="flex items-center justify-center w-full h-full bg-n-8 rounded-full">
                  <img
                    className="hover:scale-125"
                    src={artcodeySymbol}
                    width={48}
                    height={48}
                    alt="artcodey"
                  />
                </div>
              </div>
            </div>

            <ul>
              {collabApps.map((app, index) => (
                <motion.li
                  key={app.id}
                  className={`absolute top-0 ltr:left-1/2 rtl:right-1/2 h-1/2 ltr:-ml-[1.6rem] rtl:-mr-[1.6rem] origin-bottom`}
                  animate={{ rotate: index * 45 }}
                  transition={{
                    duration: 10,
                    ease: "linear",
                  }}
                >
                  <div
                    className={`relative -top-[1.6rem] flex w-[3rem] h-[3rem] bg-n-7 border border-n-1/15 rounded-xl -rotate-${
                      index * 45
                    }`}
                  >
                    <img
                      className="m-auto  hover:scale-125 "
                      width={app.width}
                      height={app.height}
                      alt={app.title}
                      src={app.icon}
                    />
                  </div>
                </motion.li>
              ))}
            </ul>

            <LeftCurve />
            <RightCurve />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;
