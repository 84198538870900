import {
  discord,
  facebook,
  figma,
  file02,
  framer,
  homeSmile,
  instagram,
  notion,
  photoshop,
  plusSquare,
  protopie,
  raindrop,
  searchMd,
  slack,
  yourlogo,
  WhatsApp,
} from "../assets";

export const gridItems = [
  {
    id: 1,
    title: "We have extensive experience in various fields, allowing us to better understand our clients' needs.",
    description: "",
    className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
    imgClassName: "w-full h-full",
    titleClassName: "justify-end",
    img: "/b1.jpg",
    spareImg: "",
  },
  {
    id: 2,
    title: "Discover new worlds with our cutting-edge tech solutions.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-end",
    img: "",
    spareImg: "/BD3.jpg",
  },
  {
    id: 3,
    title: "Diverse Technologies",
    description: "Tailored solutions with top technologies.",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "Transforming ideas into seamless user experiences.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "/grid.svg",
    spareImg: "/b4.svg",
  },

  {
    id: 5,
    title: "Ensuring high performance through continuous code optimization.",
    description: "Precise Coding",
    className: "md:col-span-3 md:row-span-2",
    imgClassName: "absolute bottom-0 md:w-96 w-60",
    titleClassName: "justify-center md:justify-start lg:justify-center",
    img: "/b5.svg",
    spareImg: "/grid.svg",
  },
  {
    id: 6,
    title: "With harmonious colors, we design interfaces that enhance the user experience.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-60 text-center",
    img: "",
    spareImg: "",
  },
];

export const navigation = [
    {
    id: "0",
    title: "Home",
    url: "/",
  },
    {
    id: "1",
    title: "Logos",
    url: "/ServicesPage/66e87d78db12dc77b9219a2c",
  },
  {
    id: "2",
    title: "Ui/Ux",
    url: "/ServicesPage/66e8807bdb12dc77b9219a37",
  },

  {
    id: "3",
    title: "apps",
    url: "/ServicesPage/66e880b4db12dc77b9219a39",
  },
  {
    id: "4",
    title: "website",
    url: "/ServicesPage/66e88133db12dc77b9219a3b",
  },
 
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const companyLogos = [yourlogo, yourlogo, yourlogo, yourlogo, yourlogo];

export const collabText =
  "Choose our company to be part of a unique creative journey, where we will work together to achieve your goals and exceed your expectations. Discover what we can offer you and embark on a digital future with confidence and creativity.";

export const collabContent = [
  {
    id: "1",
    title: "Transforming Customer Vision into Amazing Reality",
    text: "What sets us apart is our deep understanding of our clients' needs and our ability to turn their vision into tangible reality. We combine strong expertise with a comprehensive knowledge of the latest technologies to deliver stunning applications and polished websites that perfectly reflect your brand identity and interact seamlessly with your target audience.",
  },
  {
    id: "2",
    title: "Excellence and Creativity in Development and Design",
    text: "Our talented and specialized team consists of professional developers and creative designers who utilize the latest tools and technologies to achieve the highest levels of performance and aesthetics in every project we undertake. We work hard to ensure that the user experience is easy and enjoyable, and that your business goals are achieved with maximum effectiveness and success.",
  },
  {
    id: "3",
    title: "Innovation and Creativity in Unique and Exceptional Designs",
    text: "We believe in innovation and creativity, which is why we also offer unique and exceptional graphic design services. Whether you need a stunning logo that reflects your company's identity or attractive and innovative marketing materials, we are here to transform your ideas into remarkable designs that speak with a powerful voice and leave an unforgettable impression.",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 40,
    height: 40,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 40,
    height: 40,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 40,
    height: 40,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 40,
    height: 40,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 40,
    height: 40,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 40,
    height: 40,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 40,
    height: 40,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 40,
    height: 40,
  },
];

export const socials = [
  {
    id: "1",
    title: "WhatsApp",
    iconUrl: WhatsApp,
    url: "https://wa.me/967773455454",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "https://www.instagram.com/art.codey?igsh=eW0xcndoOW90aDZp",
  },

  {
    id: "4",
    title: "Facebook",
    iconUrl: facebook,
    url: "https://www.facebook.com/almaystro1010?mibextid=ZbWKwL",
  },
];
