import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};
const projectsReducer = createReducer(initialState, (builder) => {
  builder

    //! ///// all projects//////////////////
    .addCase("getAllProjectsRequest", (state) => {
      state.isLoading = true;
    })
    .addCase("getAllProjectsSuccess", (state, action) => {
      state.isLoading = false;
      state.AllProjects = action.payload;
    })
    .addCase("getAllProjectsFail", (state, action) => {
      state.isLoading = false;
      state.err = action.payload;
    });
});

export default projectsReducer;
